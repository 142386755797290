<template>
  <!-- BEGIN: Content-->
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">{{ education.id ? 'Edit' : 'New' }} education<br>
                <!-- <div class="mt-1">
                  <div class="form-check form-check-success form-switch d-flex align-items-center">
                    <input
                      id="publishContent"
                      type="checkbox"
                      class="form-check-input"
                    >
                    <label
                      class="form-check-label"
                      for="publishContent"
                    ><small class="ms-1">{{ labels['active'] }} <em
                      id="publishDate"
                      class="ms-2 text-muted"
                    ><small>12/12/2022</small></em></small></label>
                  </div>
                </div> -->
              </h2>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Back to professional background"
              @click="$router.push({name: 'researcher.education.index'})"
            ><i data-feather="chevron-left" /></a>
            <a
              v-if="loggedUser.roles.includes('super-admin')"
              class="btn btn-icon btn-flat-secondary"
              @click="$store.dispatch('modals/toggleRequestModal', 'Education')"
            ><i data-feather="settings" /></a>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">

            <SearchResearcher :item="education" />

            <div class="card">
              <div class="card-header">
                <h3 class="cart-title">
                  <a data-action="collapse">Basic information</a>
                </h3>
                <div class="heading-elements">
                  <ul class="list-inline mb-0">
                    <li>
                      <a data-action="collapse"><i data-feather="chevron-down" /></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="card-content collapse show">
                <div class="card-body">
                  <div class="row">
                    <div class="col">
                      <div class="mb-1 form-group required">
                        <label
                          for=""
                          class="form-label"
                        >{{ labels['basic_information.title'] }}</label>
                        <v-select
                          v-model="education.level"
                          label="name"
                          :options="levels"
                          :get-option-key="option => option.id"
                        />
                      </div>
                      <div class="mb-1 form-group required">
                        <label class="form-label">
                          {{ labels['basic_information.degree_in'] }}
                        </label>
                        <input
                          v-model="education.degree_in"
                          type="text"
                          class="form-control"
                        >
                      </div>
                    </div>
                  </div>
                  <hr>
                  <div class="row row-md-2">
                    <div class="col-12">
                      <div class="mb-1 form-group required">
                        <label
                          for=""
                          class="form-label d-block"
                        >{{ labels['basic_information.search_institution'] }} <a
                          class="float-end text-danger"
                          @click="toggleInstitution"
                        ><i data-feather="alert-triangle" /> <u>Click here</u> {{ labels['basic_information.not_list'] }}</a></label>
                        <v-select
                          v-if="!manualInstitution"
                          v-model="education.institution"
                          label="name"
                          :options="institutions"
                          :get-option-key="option => option.id"
                          @search="onSearch({ name: $event }, 'institutions/filter')"
                        />
                        <input
                          v-else
                          v-model="education.institution_name"
                          type="text"
                          class="form-control"
                          placeholder="Name of the institution"
                        >
                      </div>
                    </div>
                    <div class="col">
                      <div class="mb-1 form-group required">
                        <label
                          for=""
                          class="form-label"
                        >{{ labels['basic_information.begin_year'] }}</label>
                        <date-picker
                          v-model="education.begin_year"
                          format="Y"
                          type="year"
                          value-type="format"
                          class="w-100"
                        />
                      </div>
                    </div>
                    <div class="col">
                      <div class="mb-1 form-group required">
                        <label
                          for=""
                          class="form-label"
                        >{{ labels['basic_information.end_year'] }}</label>
                        <date-picker
                          v-model="education.end_year"
                          format="Y"
                          type="year"
                          value-type="format"
                          class="w-100"
                        />
                      </div>
                    </div>
                  </div>
                  <hr>
                  <div class="row">
                    <div class="col">
                      <div class="form-check form-check-success form-switch d-flex align-items-center">
                        <input
                          id=""
                          v-model="education.published_web"
                          type="checkbox"
                          class="form-check-input"
                        >
                        <label
                          class="form-check-label"
                          for=""
                        ><small class="ms-1">{{ labels['basic_information.active'] }} </small></label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <AddFile
              :id="6"
              :title="'Files'"
              :type="3"
              :files="education.certificates"
              @saveAnswer="saveFiles"
            />

            <AttachedForm
              :type="'Education'"
              :model-id="educationId"
            />
          </div>
        </div>
        <div class="page-actions">
          <button
            v-if="!loading"
            id="type-success"
            class="btn btn-success"
            @click="save"
          >
            <i data-feather="save" /> <span class="ms-25 align-middle"> Save and Exit</span>
          </button>
          <button
            v-if="loading"
            class="btn btn-outline-success"
            type="button"
            disabled=""
          >
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
            <span class="ms-25 align-middle">Saving...</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'
import DatePicker from 'vue2-datepicker'
import AddFile from '../../admin/senior-call/components/AddFile.vue'
import SearchResearcher from '../../partials/components/SearchResearcher.vue'
import 'vue2-datepicker/index.css'
import AttachedForm from '../../forms/partials/AttachedForm.vue'

export default {
  components: {
    vSelect,
    AddFile,
    SearchResearcher,
    DatePicker,
    AttachedForm,
  },
  data() {
    return {
      educationId: this.$route.params.id,
      loading: false,
      selectedInstitution: null,
      manualInstitution: false,
    }
  },
  computed: {
    ...mapGetters({
      education: 'educations/education',
      institutions: 'institutions/institutions',
      levels: 'levels/levels',
      labels: 'sectionLabels/itemLabels',
      loggedUser: 'auth/admin',
      attached: 'requestForms/attached',
    }),
  },
  async mounted() {
    this.$store.dispatch('sectionLabels/fetchLabels', 'my_profile.educations')
    if (this.educationId) {
      await this.$store.dispatch('educations/fetchEducation', this.educationId)
      this.selectedInstitution = this.education.institution
    } else {
      this.$store.dispatch('educations/clear')
    }

    if (this.levels.length === 0) {
      this.$store.dispatch('levels/fetch')
    }

    this.manualInstitution = this.education.institution_name != null && this.education.institution_name !== ''
  },
  methods: {
    toggleInstitution() {
      if (!this.manualInstitution) {
        this.education.institution = null
      } else {
        this.education.institution_name = null
      }

      this.manualInstitution = !this.manualInstitution
    },
    async save() {
      this.loading = true
      this.education.attached_form = this.attached
      try {
        if (this.educationId) {
          await this.$store.dispatch('educations/update', { id: this.educationId, data: this.education })
        } else {
          await this.$store.dispatch('educations/create', this.education)
        }
        this.errors = ''
      } catch (e) {
        this.errors = e.response.data.message
      }

      if (this.errors === '' && this.education.id) {
        Vue.swal('Education updated successfully!', '', 'success').then(() => {
          this.$router.push({ name: 'researcher.education.index' })
        })
      } else {
        // Vue.swal
      }

      this.loading = false
    },
    saveFiles(files) {
      this.education.files_new = files.data
    },
    async onSearch(search, store) {
      search.active = true
      if (search.name !== '') {
        await this.$store.dispatch(store, search)
      }
    },
  },
}
</script>
